import React from 'react'
import { Grid, Row } from 'react-flexbox-grid'
import styled from 'styled-components'
import { BoxShadow } from '../const/styles'

const StyledGrid = styled(Grid)`
  padding: 0;
`
const StyledWrapper = styled(Row)`
  margin-left: -16px;
  margin-right: -16px;
  > p {
    flex: 1;
    width: 100%;
    padding: 0 16px;
    flex-basis: 50%;
    max-width: 50%;
    margin-bottom: 1.8rem;
    //@media (min-width: 768px) {
    //  flex-basis: 33.33%;
    //  max-width: 33.33%;
    //}
    //@media (min-width: 992px) {
    //  flex-basis: 25%;
    //  max-width: 25%;
    //}
  }

  img {
    ${BoxShadow}
  }
`

const SlideShow = ({ children }) => {
  return (
    <StyledGrid fluid>
      <StyledWrapper className="slideshow-wrapper">{children}</StyledWrapper>
    </StyledGrid>
  )
}

export default SlideShow
