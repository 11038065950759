import Typography from 'typography'
import Wordpress2016 from 'typography-theme-wordpress-2016'
import theme from '../const/theme'

Wordpress2016.overrideThemeStyles = () => {
  return {
    a: {
      boxShadow: `none`,
      color: theme.colors.green,
    },
    h1: {
      fontFamily: `ff-nexus-mix, san-serif`,
      fontSize: `2rem`,
      fontStyle: `italic`,
      fontWeight: `normal`,
      color: theme.colors.gray_darker,
    },
  }
}

delete Wordpress2016.googleFonts
const fontFamily = [
  theme.font.san_serif,
  '-apple-system',
  'Roboto',
  'sans-serif',
]
Wordpress2016.headerFontFamily = fontFamily
Wordpress2016.bodyFontFamily = fontFamily
Wordpress2016.blockMarginBottom = 0.8
Wordpress2016.baseFontSize = `21px`
Wordpress2016.baseLineHeight = 1.5
Wordpress2016.bodyColor = theme.colors.gray_dark

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
