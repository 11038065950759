import { css } from 'styled-components'
import theme from './theme'
import { rhythm } from '../utils/typography'

export const BoxShadow = css`
  transition: all 0.3s ease-in-out;
  box-shadow: 0 1px 1px
      rgba(
        ${(props) => (props.shadow ? theme.hexToRgb(props.shadow) : `0,0,0`)},
        0.02
      ),
    0 2px 2px
      rgba(
        ${(props) => (props.shadow ? theme.hexToRgb(props.shadow) : `0,0,0`)},
        0.04
      ),
    0 3px 3px
      rgba(
        ${(props) => (props.shadow ? theme.hexToRgb(props.shadow) : `0,0,0`)},
        0.06
      ),
    0 6px 6px
      rgba(
        ${(props) => (props.shadow ? theme.hexToRgb(props.shadow) : `0,0,0`)},
        0.02
      );
  :hover {
    box-shadow: 0 1px 1px
        rgba(
          ${(props) => (props.shadow ? theme.hexToRgb(props.shadow) : `0,0,0`)},
          0.04
        ),
      0 2px 2px
        rgba(
          ${(props) => (props.shadow ? theme.hexToRgb(props.shadow) : `0,0,0`)},
          0.06
        ),
      0 4px 4px
        rgba(
          ${(props) => (props.shadow ? theme.hexToRgb(props.shadow) : `0,0,0`)},
          0.08
        ),
      0 8px 8px
        rgba(
          ${(props) => (props.shadow ? theme.hexToRgb(props.shadow) : `0,0,0`)},
          0.08
        );
  }
`

const pageWidthVal = parseInt(theme.pageWidth.replace('px', ''))
const articleWidthVal = parseInt(theme.articleWidth.replace('px', ''))

export const PageWidthOverride = css`
  margin-left: ${((pageWidthVal - articleWidthVal) / 2) * -1}px!important;
  margin-right: ${((pageWidthVal - articleWidthVal) / 2) * -1}px!important;
  width: ${theme.pageWidth};
`

export const PageBound = css`
  margin-left: auto;
  margin-right: auto;
  max-width: ${theme.pageWidth};
  padding: ${rhythm(0.2)};
  @media (min-width: 768px) {
    padding: ${rhythm(0.5)};
  }
`
