const theme = {
  pageWidth: `1200px`,
  articleWidth: `900px`,
  colors: {
    gray: `#f0f3f3`,
    gray_dark: `#777777`,
    gray_darker: `#333333`,
    blue: `#3B8FD9`,
    blue_light: `#dfedf9`,
    green: `#1DB5A5`
  },
  font: {
    san_serif: 'ff-scala-sans-pro'
  },
  hexToRgb: function (hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b
    })

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    const r = parseInt(result[1], 16)
    const g = parseInt(result[2], 16)
    const b = parseInt(result[3], 16)
    return result ? `${r}, ${g}, ${b}` : null
  },
}

export default theme
